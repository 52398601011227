import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {isSignedIn} from "../../lib/auth";
import {useRouter} from "next/router";

type AuthContextType = {
  isAuthenticated: boolean
  setIsAuthenticated: (v: boolean) => void
  redirectPath: string
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  redirectPath: '/'
});

export const useAuthContext = () => useContext(AuthContext)

type Props = {
  children: ReactNode
}

const NoAuthPages = [
  '/reset_password',
  '/sign_in',
  '/admin',
  '/line/complete',
  '/line/liff'
]

export const AuthProvider = ({ children }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [redirectPath, setRedirectPath] = useState('/')
  const router = useRouter()

  useEffect(() => {
    if (!NoAuthPages.find((p) => router.pathname.startsWith(p))) {
      isSignedIn().then((auth) => {
        setIsAuthenticated(auth)
        if (!auth) {
          setRedirectPath(router.asPath)
          router.replace('/sign_in')
        }
      })
    }
  }, [])

  return <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, redirectPath }}>{children}</AuthContext.Provider>
}
