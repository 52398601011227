import {CognitoRefreshToken, CognitoUser, CognitoUserPool} from "amazon-cognito-identity-js";

const PoolData = {
  UserPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID as string,
  ClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID as string,
};

type SignInRequestBody = { email: string, password: string }

export const isSignedIn = async () => {
  if (!!localStorage.getItem('accessToken')) {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_HOST}/api/v1/self`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    })
    if (!response.ok) {
      signOut()
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

export const signIn = async (body: SignInRequestBody) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_HOST}/api/v1/sessions/ecforce`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }
  )
  if (!response.ok) return false
  const responseBody = await response.json()

  if (!responseBody) return false
  localStorage.setItem('loginMethod', 'cognito')
  localStorage.setItem('accessToken', responseBody.data.accessToken)
  localStorage.setItem('accessToken.payload.exp', responseBody.data.accessTokenExp)
  localStorage.setItem('refreshToken', responseBody.data.refreshToken)
  localStorage.setItem('username', responseBody.data.username)

  return true
}

type SignInLineRequestBody = { idToken: string }

// idTokenをlocalStorageのaccessTokenに入れておく
// BEの認証ではcognito→lineの順番で行う
export const signInLine = async (body: SignInLineRequestBody) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_HOST}/api/v1/sessions/line`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }
  )
  if (!response.ok) return false
  const responseBody = await response.json()

  if (!responseBody) return false
  localStorage.setItem('loginMethod', 'line')
  localStorage.setItem('accessToken', body.idToken)
  localStorage.setItem('accessToken.payload.exp', responseBody.exp)

  return true
}

export const signOut = () => {
  localStorage.removeItem('loginMethod')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('accessToken.payload.exp')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('username')
}

export const refresh = () => {
  return new Promise<void>((resolve) => {
    const exp = parseInt(localStorage.getItem('accessToken.payload.exp') || '0') * 1000
    const now = new Date().getTime()
    const loginMethod = localStorage.getItem('loginMethod')
    // line id tokenのrefreshはline側で行うため不要
    if (now > exp && loginMethod != 'line') {
      const token = localStorage.getItem('refreshToken') || ''
      const username = localStorage.getItem('username') || ''
      const userPool = new CognitoUserPool(PoolData)
      const cognitoUser = new CognitoUser({Username: username, Pool: userPool})
      const refreshToken = new CognitoRefreshToken({RefreshToken: token})
      cognitoUser.refreshSession(refreshToken, (_, session) => {
        if (session?.isValid()) {
          localStorage.setItem('accessToken', session.accessToken.jwtToken)
          localStorage.setItem('accessToken.payload.exp', session.accessToken.payload.exp)
          localStorage.setItem('refreshToken', session.refreshToken.token)
          localStorage.setItem('username', session.accessToken.payload.username)
        }
        resolve()
      })
    } else {
      resolve()
    }

  })
}

