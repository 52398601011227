import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const theme = extendTheme({
  config,
  colors: {
    brand: {
      100: '#F7F9F7', // light button
      300: '#F0F8F6', // input area
      400: '#C4C4C4', // gray button
      500: '#9B9B9B', // gray text
      600: '#E2943B', // yellow
      700: '#B69749', // yellow
      900: '#2E3A2D'  // default text
    }
  },
  components: {
    Radio: {
      variant: {
        brand: {
          borderColor: 'brand.400',
          borderWidth: '1px',
          style: {
            width: '20px',
            height: '20px',
            bg: 'transparent'
          },
          _checked: {
            width: '20px',
            height: '20px',
            bg: 'transparent',
            borderColor: 'brand.600',
            borderWidth: '1px',
            _before: {
              width: '8px',
              height: '8px',
              bg: 'brand.600',
              content: '""',
              rounded: 'full'
            }
          }
        }
      }
    }
  }
})

export default theme
