import {createContext, ReactNode, RefObject, useContext, useRef} from "react";

type HeaderContextType = {
  leftPortalRef: RefObject<HTMLDivElement>
  rightPortalRef: RefObject<HTMLDivElement>
}

const HeaderContext = createContext<HeaderContextType>({} as HeaderContextType)

export const useHeader = () => {
  return useContext(HeaderContext)
}

type Props = {
  children: ReactNode
}

export const HeaderProvider = ({ children }: Props) => {
  const leftPortalRef = useRef<HTMLDivElement>(null)
  const rightPortalRef = useRef<HTMLDivElement>(null)

  return (
    <HeaderContext.Provider
      value={{
        leftPortalRef,
        rightPortalRef
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}
