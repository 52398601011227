import useSWR from "swr";
import {useState} from "react";
import {useToast} from "@chakra-ui/react";
import {refresh} from "../../lib/auth";

const headers = {
  'Accept': 'application/json',
}

const authorizationHeader = (accessToken: string) => accessToken ? {
  'Authorization': `Bearer ${accessToken}`
} : {}

export const useApi = <T>(path?: string | null) => {
  const fetcher = async (url: string) => {
    await refresh(); // アクセストークンが切れていたらrefreshしてくれる
    const accessToken = localStorage.getItem('accessToken') as string
    const request = () => fetch(
      url,
      {
        // @ts-ignore
        headers: {
          ...headers,
          ...authorizationHeader(accessToken),
        }
      }
    )
    return request()
      .then(res => res.json())
      .catch(err => console.log(err))
  };
  return useSWR<T>(path, (key) => fetcher(`${process.env.NEXT_PUBLIC_API_HOST}/api/${key}`))
}

export const useMutateApi = <T, Res>(method: 'POST' | 'PUT' | 'DELETE', path: string, withAuth: boolean = true) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Res>()
  const toast = useToast()

  const request = async (body: T | BodyInit, isFormData?: boolean) => {
    setLoading(true)
    if (withAuth) await refresh(); // アクセストークンが切れていたらrefreshしてくれる
    const accessToken = localStorage.getItem('accessToken') as string

    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_HOST}/api/${path}`,
      {
        method,
        // @ts-ignore
        headers: isFormData ? {
          ...headers,
          ...authorizationHeader(accessToken),
        } : {
          ...headers,
          ...authorizationHeader(accessToken),
          'Content-Type': 'application/json',
        },
        // @ts-ignore
        body: isFormData ? body : JSON.stringify(body)
      }
    )
    response.ok || toast({
      title: 'エラー',
      description: "更新できませんでした",
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
    const res = await response.json()
    setData(res.data as Res)
    setLoading(false)

    return res.data
  }

  return {
    loading,
    data,
    request
  }
}
