import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {ChildWithType} from "../../model/child";
import {useApi} from "./useApi";
import {useAuthContext} from "./AuthContext";
import * as Sentry from "@sentry/nextjs";

type ChildContextType = {
  currentChild?: ChildWithType
  setCurrentChild: (child: ChildWithType) => void
  children: ChildWithType[]
  refresh: () => Promise<void>
}

const ChildContext = createContext<ChildContextType>({} as ChildContextType);

export const useChild = () => {
  return useContext(ChildContext)
}

type Props = {
  children: ReactNode
}

export const ChildProvider = ({ children }: Props) => {
  const { isAuthenticated } = useAuthContext()
  const { data, mutate } = useApi<{ children: ChildWithType[] }>(isAuthenticated ? `v1/self/children?with_detail=true` : null)
  const childrenData = data?.children || [];
  const [currentChild, setCurrentChild] = useState<ChildWithType>()

  useEffect(() => {
    if (data && !currentChild) {
      if (childrenData.length === 0) {
        Sentry.setUser({ id: localStorage.getItem('username') as string })
        Sentry.captureException(new Error('No child found'))
      } else {
        setCurrentChild(childrenData[0])
      }
    }
  }, [data])

  const refresh = async () => {
    await mutate()
  }

  return <ChildContext.Provider value={{ currentChild, setCurrentChild, children: childrenData, refresh }}>{children}</ChildContext.Provider>
}
