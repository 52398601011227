import '../styles/globals.css'
import type { AppProps } from 'next/app'
import {ChakraProvider} from "@chakra-ui/react";
import theme from "../theme";
import React from "react";
import Head from "next/head";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {AuthProvider} from "../components/lib/AuthContext";
import {ChildProvider} from "../components/lib/ChildContext";
import {HeaderProvider} from "../components/lib/HeaderContext";

function App({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <AuthProvider>
          <HeaderProvider>
            <ChildProvider>
              <Head>
                <link rel="icon" type="image/png" href="/icon.png" />
                <title>クラウンボックス</title>
                <link
                  href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap"
                  rel="stylesheet"
                />
              </Head>
              <Component {...pageProps} />
            </ChildProvider>
          </HeaderProvider>
        </AuthProvider>
      </DndProvider>
    </ChakraProvider>
  )
}

App.getInitialProps = async () => ({ pageProps: {} })

export default App
